import React from "react";
import './App.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { ethers } from 'ethers'
import { useState, useEffect } from 'react';
import axios from 'axios'

import boegimg from './assets/boeg_final.png'
import checkmark from './assets/checkmark.png'
import high_resolution from './assets/3602.svg'
import authentic from './assets/authentic2.svg'
import fast_transfer from './assets/fast_transfer2.svg'
import historical from './assets/historical2.svg'
import metaverse from './assets/metaverse2.svg'
import transparency from './assets/transparency2.svg'
import opensea from './assets/opensea2.svg'
import link from './assets/external_link.svg'
import angle_down from './assets/angle-down.svg'
import lock from './assets/lock2.svg'
import nft_faq from './assets/nft-faq2.svg'
import shopping_bag from './assets/shopping-bag2.svg'
import snowman from './assets/snowman2.svg'
import eth from './assets/eth2.svg'
import card from './assets/credit-card.svg'
import wallet from './assets/wallet2.svg'
import logo from './assets/toleologo.svg'
import invoice from './assets/invoice.svg'
import combineImg from './assets/combine.png'


import { loadStripe } from "@stripe/stripe-js";

import {
  Contract,
  Abi,
  targetChain,
  hexchainId,
  testmode,
  stripeprod,
  stripetest,
  host,
  mumbaiRelay,
  ethRelay,
  mailchimp,
  ethprice,
  chfprice
} from './assets/config'


function App() {

  const EtherAddress = "https://etherscan.io/address/" + Contract
  const [walletAddress, setAddress] = useState([])
  //const [counter, setcounter] = useState([])
  const [priceETH, setpriceETH] = useState([])
  const [priceCHF, setpriceCHF] = useState([])
  const [topayCHF, settopayCHF] = useState([chfprice])
  const [topayETH, settopayETH] = useState([ethprice])
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenZunft, setIsOpenZunft] = useState(false);
  const [isOpenThank, setIsOpenThank] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [crypt, setcrypto] = useState([false])
  const [chf, setchf] = useState([false])
  const [answer, setAnswer] = useState(0)
  const faqArr= [
    {
      src:nft_faq,
      alt:"nft icon",
      text:"Was sind NFTs und wofür werden sie genutzt?",
      answer:"Ein NFT (Non-Fungible Token) ist ein digitales Gut, das eine einzigartige Identität hat und nicht austauschbar ist. Es wird auf einer Blockchain-Plattform gespeichert und verwaltet, was es zu einem sicheren und transparenten digitalen Besitznachweis macht. Da jedes NFT einzigartig ist, kann es nicht einfach durch ein anderes NFT ersetzt werden, was es zu einem besonderen und wertvollen Besitz macht.",
      answer2: "NFTs können verwendet werden, um digitale Kunstwerke, Musik, Videos, Sammlerstücke und vieles mehr zu repräsentieren. Über die Blockchain Technologie ist es zudem möglich, NFTs in Echtzeit zu transferieren und ihren Ursprung zurückzuverfolgen. So können Provenienz, Authentizität und Liquidität der digitalen Güter sichergestellt werden. Dies kommt bereits heute z.B. in der Immobilienbranche, dem Kunstmarkt, sowie anderen Sammlermärkten zur Anwendung."
    },
    {
      src:shopping_bag,
      alt:"shopping_bag",
      text:"Wo und wie kann ich NFTs kaufen?",
      answer:"NFTs kann man auf verschiedene Arten kaufen. Meist geschieht der erste Verkauf eines NFTs über die Website des Herausgebers oder über bestimmte Platformen wie OpenSea, Rarible und Manifold.",
      answer2:"Ausserdem gibt es einen Sekundärmarkt für NFTs, bei dem der Handel ebenfalls über gängige Marktplätze abgewickelt wird. Zu diesen Marktplätzen gehören unter anderem OpenSea, SuperRare, Blur, und Looksrare."
    },    {
      src:wallet,
      alt:"wallet",
      text:"Wie kann ich ein eigenes Wallet für den Kauf von Cryptowährungen und NFTs erstellen?",
      answer:"Es gibt verschiedene Möglichkeiten NFTs sicher aufzubewahren. In jedem Fall braucht man ein Wallet- eine digitale Geldbörse, mit der man Cryptowährungen und NFTs halten und versenden kann. Es gibt zwei Typen von Wallets: Hardware Wallets, welche die Vermögenswerte auf einem physischen Gerät speichern und Software Wallets, welche oft als Apps oder Browser Add-Ons zu integrieren sind und rein digital verwaltet werden.",
      answer2:"Wir empfehlen für Hardware Wallets die Produkte der Firma “Ledger”, für die Erstellung eines Software Wallets bei Marktführer MetaMask gibt es hier einen PDF Guide."
    },    {
      src:snowman,
      alt:"snowman",
      text:"Warum gibt es den E-Böögg NFT?",
      answer:"Das Säächsilüüte ist ein wichtiger kultureller Pfeiler in der Geschichte der Stadt Zürich und ihrer Zünfte. Wir schaffen anlässlich des zünftigen Frühlingsfests 2023 ein digitales Sammlerobjekt, welches die Tradition des Sächsilüüte mit dem Thema Innovation in Form von NFTs verbindet.",
      answer2: "Ziel ist es, die historischen, gewerberechtlichen und heidnischen Wurzeln des Frühlingsfests als Memorabilia für Zunftmitglieder in einem digitalen Kontext neu zu verankern. Als Sinnbild dieser Tradition, schmückt der Böögg auch unser digitales Sammlerobjekt zum Säächsilüüte 2023."
    },    {
      src:lock,
      alt:"lock",
      text:"Wie gehe ich sicher mit den digitalen Coins und Tokens in meinem Wallet um?",
      answer:"Wallets sind meist einzig und allein in Ihren Händen und somit auch in Ihrer Verantwortung. Das bedeutet, dass Sie allein die Sicherheit ihrer digitalen Besitztümer gewährleisten müssen. Aus diesem Grund ist es umso wichtiger, sich vor Betrug, Phishing und Hacks zu schützen. Die sogenannten Keys und Seed Phrases, die beim Aufsetzen eines Wallets erstellt werden, müssen in jedem Fall sicher aufbewahrt und nicht geteilt werden.",
      answer2:"Zudem sollten Walletbesitzer nur mit vertrauten Links interagieren, Transaktionen vor der Bestätigung sorgfältig prüfen und Vermögenswerte ggf. auf mehrere Wallets aufteilen. Wir empfehlen zur Vollständigkeit, dass Sie sich zu weiteren Optionen und Strategien zum sicheren Umgang mit ihrem Wallet ausreichend informieren."
    },  ];

  // const SimpleForm = () => <MailchimpSubscribe url={mailchimp}/>

  useEffect(() => {
    // call api or anything

    connect()
  })

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setError(null);
    
    if (isValidEmail(email)) {

      
      let first_name = document.getElementById("first_name").value
      let last_name = document.getElementById("last_name").value
      let email = document.getElementById("email").value
      let ele = document.getElementsByName("zunft")
      let value
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].checked) {
          value = ele[i].id
          break;
        }
      }
      /*
      let data =
        {
            members:[
                {
                    email_adress:email,
                    status:"subscribed",
                    merge_fields:
                        {
                            FNAME:first_name,
                            LNAME:last_name,
                            "ADDRESS": {
                              "addr1": value                   
                        }
                        }
                }
            ]
        };
        */
      //let url = mailchimp + ";fname="+first_name+";lname="+last_name+";address="+value+";email_address="+email
      //axios.post(mailchimp, data)
      fetch(mailchimp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email_address: email,
          status: 'subscribed',
          merge_fields:
            {
                FNAME:first_name,
                LNAME:last_name,
                "ADDRESS": {
                  "addr1": value                   
              }
          },
        }),
      });
      
      setSuccess('Versandt!');
    } else {
      setError('Email is invalid');
    }
  };

  const toggling = () => setIsOpen(!isOpen);

  const togglingZunft = () => setIsOpenZunft(!isOpenZunft);

  const togglingModal = () => {
    setIsOpenModal(!isOpenModal)
    document.body.classList.toggle('overflow');
  };

  const togglingThank = () => {
    window.open("/successinvoice", "_self")
    /*
    setIsOpenThank(!isOpenThank)
    document.body.classList.toggle('overflow');
    */
  };

  /*
  var x = setInterval(function () {
    
    var countDownDate = new Date("May 1, 2023 18:00:00").getTime();
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // Display the result in the element with id="demo"
    document.getElementById("days").innerHTML = days
    document.getElementById("hours").innerHTML = hours
    document.getElementById("minutes").innerHTML = minutes
    document.getElementById("seconds").innerHTML = seconds

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("openModalBtn").classList.add("hidden");
      document.getElementById("saleEnds").classList.add("hidden")
      document.getElementById("saleEnded").classList.remove("hidden")
    }
  }, 1000);
*/
  async function connect() {
    let nowallet = document.getElementById("nowallet")

    let pri = ethprice
    setpriceETH(pri)
    setpriceCHF(chfprice)

    let provider
    try {
      provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      )
      nowallet.classList.add("hidden")
    } catch(e) {
      nowallet.classList.remove("hidden")
    }

    provider.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });

    // check if on correct network
    let chainId = await provider.getNetwork();
    if (chainId.chainId != targetChain) {
      try {
        // call the switch
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexchainId }],
        });

      } catch (e) {

        console.log(e)
      }
    } else {
      // declare signer
      const signer = provider.getSigner()
      const nftContract = new ethers.Contract(Contract, Abi, signer)

      // ask for wallet connection
      await provider.send("eth_requestAccounts", [])
      let address = (await signer.getAddress()).toString()
      setAddress(address)
      setcrypto(true)

      //let ctn = await nftContract.counter()
      //setcounter(ctn.toString())
      //setpriceETH(ethers.utils.formatUnits(pri.toString().toString(), 'ether'));
      setcrypto(true)
      setchf(true)      

      let connectbtn = document.getElementById("connectBtn");
      //connectbtn.disabled = true;

      let addressToPrint = address.substr(0, 4) + "..." + address.substr(38, 42)
      connectbtn.innerHTML = addressToPrint

    }
  }

  async function buyEth() {
    let error = document.getElementById("cryptoError")
    error.classList.add("hidden")
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    )

    // declare signer
    const signer = provider.getSigner()
    const nftContract = new ethers.Contract(Contract, Abi, signer)

    let toMint = parseInt(document.getElementById("mintAmount").innerText)

    //const price = await nftContract.price();
    //const finalPay = ethers.BigNumber.from((Number(price) * toMint).toString())
    let payable = ethprice * toMint

    const finalPay = ethers.utils.parseUnits(payable.toString())

    let address = (await signer.getAddress()).toString()

    let balance = await provider.getBalance(address)
    if(Number(balance) >= Number(finalPay)) {

      try {
        const transaction = await nftContract.mint(address, toMint, {
          value: finalPay
        })
        document.getElementById("payCrypto").innerText = "Pending..."
        await transaction.wait()
        window.location.href = "/success"
      } catch (e) {
        console.log(e)
        document.getElementById("mintError").innerHTML = "Transaktionsfehler!"
      }

    } else {
      error.classList.remove("hidden")
    }
  }

  async function buyChf() {

    if (ethers.utils.isAddress(walletAddress)) {
      let stripe

      //if (testmode == 1) {
       // stripe = await loadStripe(stripetest)
      //} else {
        stripe = await loadStripe(stripeprod)
      //}
      let amount = document.getElementById("mintAmount").innerText

      const headers = {
        "Content-Type": "application/json",
      };

      const response = await fetch(
        host + "/checkout/" + walletAddress + "/" + amount,
        {
          method: "POST",
          headers: headers,
        }
      );

      const session = await response.json();

      const result = stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.log(result.error);
      }
    }
  }

  function openModal() {
    document.getElementById("walleterror").classList.add("hidden")
    if (ethers.utils.isAddress(walletAddress)) {
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
      document.getElementById("main").classList.add("modalblur")
    } else {
      document.getElementById("walleterror").classList.remove("hidden")
    }


  }

  function closeModal() {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    document.getElementById("main").classList.remove("modalblur")
  }


  function minusAmount() {
    var amount = parseInt(document.getElementById("mintAmount").innerText);
    amount = amount - 1;
    if (amount < 1) {
      amount = 1;
    }

    document.getElementById("mintAmount").innerText = amount;
    let newvalue = priceETH * amount
    let newchf = priceCHF * amount

    if(newvalue < 0.9 && newvalue > 0.8) {
      newvalue = 0.9
    }
    if(newvalue < 1.8 && newvalue > 1.7) {
      newvalue = 1.8
    }
    if(newvalue < 2.7 && newvalue > 2.6) {
      newvalue = 2.7
    }

    settopayETH((newvalue).toString().substring(0,5))
    settopayCHF(newchf)
  }

  function addAmount() {
    var amount = parseInt(document.getElementById("mintAmount").innerText);
    amount = amount + 1;
    if (amount > 10) {
      amount = 10;
    }

    document.getElementById("mintAmount").innerText = amount;
    let newvalue = priceETH * amount
    let newchf = priceCHF * amount

    if(newvalue < 0.9 && newvalue > 0.8) {
      newvalue = 0.9
    }
    if(newvalue < 1.8 && newvalue > 1.7) {
      newvalue = 1.8
    }
    if(newvalue < 2.7 && newvalue > 2.6) {
      newvalue = 2.7
    }

    settopayETH((newvalue).toString().substring(0,5))
    settopayCHF(newchf)
  }

  function changepay() {
    let ele = document.getElementsByName("paymethod")
    let value
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        value = ele[i].value
        break;
      }
    }

    if (value == "crypto") {
      document.getElementById("payCrypto").classList.remove("hidden")
      document.getElementById("payCredit").classList.add("hidden")
      document.getElementById("invoiceex").classList.add("hidden")
      document.getElementById("payInvoice").classList.add("hidden")
    } else {
      if (value == "invoice") {
        document.getElementById("invoiceex").classList.remove("hidden")
        document.getElementById("payInvoice").classList.remove("hidden")
        document.getElementById("payCrypto").classList.add("hidden")
        document.getElementById("payCredit").classList.add("hidden")
      } else {
        document.getElementById("payCrypto").classList.add("hidden")
        document.getElementById("payCredit").classList.remove("hidden")
        document.getElementById("invoiceex").classList.add("hidden")
        document.getElementById("payInvoice").classList.add("hidden")
      }
    }
  }

  function changeAGB() {
    let btn = document.getElementById("agb")
    let section = document.getElementById("buybuttons")
    if(btn.checked) {
      section.classList.remove("hidden")
    } else {
      section.classList.add("hidden")
    }

  }

  return (
    <div id="main" className="App text-white bg-black">
      <div className="page-padding">
      <div className="container-large">

      <div className="flex items-center justify-between" id="header">
        <div className="" id="">
          <img src={logo} className="logo" alt="main logo"></img>
        </div>
        <div className="" id="">
          <button className="hover:underline" id="connectBtn" onClick={connect}>Connect Wallet</button>
          <div className="hidden" id="nowallet">
            <p className="text-[14px]" >Kein Wallet vorhanden.<br></br>Installiere Metamask oder <br></br>benutze den Browser <br></br>in der Metamask App</p>
          </div>
        </div>
      </div>


      <div className="" id="banner">
        <div className="" id="headerimage">
          <div className="mx-auto" id="">
            <img src={combineImg} className="mx-auto boeg-image" alt="main nft"></img>
            {/* <img src={checkmark} className="mx-auto w-1/2 checkmark-image" alt="checkmark"></img> */}
          </div>
        </div>
        <div className="" id="buysection">
          <div className="title-wrapper">
          <h1 className="maintitle">Dä E-Böögg</h1>
          <h1 className="maintitle">NFT isch da!</h1>
          </div>
          <p className="lead">Das digitale Sammlerstück zum Sächsilüüte 2023</p>
          <div className="button-wrapper">
            <div className="">
              {/*<button className="" id="openModalBtn" onClick={togglingModal}>Jetzt kaufen</button><br></br>*/}
            </div>
             <p className="hidden mt-4 mb-4 float-right text-right text-orange-600" id="walleterror">Es ist keine valide Ethereum Adresse verbunden!</p>
             <a href={require("./assets/Toleo.pdf")} target="_blank" className="" id="walletlink" >&gt; Wie erstelle ich ein eigenes Crypto Wallet?</a>
         </div>
        </div>
      </div>
      
      
      <div className="" id="saleEnds">
      <div className="" id="saleEnded">
        <h2 className="sale-ends-title">
            Minting beendet seit dem 01.05.2023
        </h2>
      </div>
        {/* 
        <div className="sale-timer">
          <h2 className="sale-ends-title">
            Minting endet für immer in
          </h2>
          <ul className="timer-list">
            <li className="timer-item">
              <p className="time" id="days">
                0
              </p>
              <p className="period">
                Tagen
              </p>
            </li>
            <li className="timer-item">
              <p className="time" id="hours">
                0
              </p>
              <p className="period">
                Stunden
              </p>
            </li>
            <li className="timer-item">
              <p className="time" id="minutes">
                0
              </p>
              <p className="period">
                Minuten
              </p>
            </li>
            <li className="timer-item">
              <p className="time" id="seconds">
                0
              </p>
              <p className="period">
                Sekunden
              </p>
            </li>
          </ul>
        </div>
        */}
        <div className="sale-dropdown-wrapper">
          <a href="https://opensea.io/collection/boeoegg-nft-2023" target="_blank"><img className="opensea" src={opensea} alt="opensea"></img></a>
          <div className="sale-details">
            <div className="dropdown-header" onClick={toggling}>
              <p className="dropdown-title">
                <span>NFT Details</span>
                 <img className={`angle-icon ${isOpen ? 'open' : ''}`} src={angle_down} alt="angle-down"></img>
              </p>
              {isOpen && (
                <ul className="sale-details-list">
                  {/* 
                  <li className="details-list-item">
                    <a href="#" className="outer-link">original media <img className="outer-link" src={link} alt="outer link"></img></a>
                    <p className="token">HTTP...FFJO</p>
                  </li>
                  */}
                  <li className="details-list-item">
                    <a href="https://etherscan.io/address/0xCa266D356E0e5450E89658C2A836d6f8DB54e1bc#code" target="_blank" className="outer-link">redeem contract <img className="outer-link" src={link} alt="outer link"></img></a>
                    <p className="token">0XCA26...E1BC</p>
                  </li>
                  <li className="details-list-item">
                    <p>
                      Mint Ende
                    </p>
                    <p>
                      1.5.2023 18:00
                    </p>
                  </li>
                  <li className="details-list-item">
                    <p>
                      Token Type
                    </p>
                    <p>
                      erc721
                    </p>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-40 -mb-16 text-2xl md:text-3xl xl:text-4xl font-bold">
        <div className="banner">
          <p>Dieser NFT bietet Zugang zu einem Club mit exklusiven Leistungen (NFT Utilities). Zum Beispiel:</p><br></br>
          <p>Der E-Böögg NFT gilt als Eintrittsticket für gemütlichen Abend im Zunfthaus zur Waag mit einem 3 Gang Abendessen inkl. Getränke sowie einer Einführung ins Thema NFTs und Metaverse (ausprobieren) am 21.9.2023</p><br></br>
          <p>Zugang nur für E-Böögg NFT Besitzer</p><br></br>
          <p>Weitere Vorteile (Utilities) für E-Böögg NFT Besitzer folgen</p>
        </div>
      </div>

      <div className="" id="why">
        <h2 className="h2title">Warum E-Böögg NFT?</h2>
        <div className="why-wrapper">
          <div className="why-item">
            <img className="icon" src={historical} alt="historical"></img>
            <h3 className="h3title">
              <span>Historisches</span>
              <span>Sammlerstück</span>
               </h3>
            <p className="cardtext">Mit dem E-Böögg NFT erhält das Sechseläuten 2023 erstmals ein digitales Sammlerstück. Der Böögg ist hierbei das zentrale Symbol mit dem wir Innovation und Tradition verbinden. Ein Meilenstein in der Historie des Fests.</p>
          </div>
          <div className="why-item">
            <img className="icon" src={high_resolution} alt="high resolution"></img>
            <h3 className="h3title">
            <span>Der Böögg in</span>
              <span>hochauflösendem 3D</span>
               </h3>
            <p className="cardtext">Mit dem NFT erhalten sie die 3D Datei zum Böögg Model - gespeichert auf Lebzeiten. Entdecken Sie alle Facetten des 2023 Bööggs in 360° am Desktop oder drucken Sie Ihre eigenen Böögg Figuren mit gängigen 3D-Druckern.</p>
          </div>
          <div className="why-item">
            <img className="icon" src={metaverse} alt="metaverse"></img>
            <h3 className="h3title">
            <span>Bereit für</span>
              <span>das Metaverse</span>
               </h3>
            <div>
              <p className="cardtext">Der E-Böögg NFT ist Metaverse kompatibel. Setzen Sie Ihre neue Böögg Skulptur in allen gängigen 3D-Metaversen in Szene.</p>
              <p className="cardtext">Wir helfen Ihnen gerne bei weiteren Fragen dazu.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="" id="why">
        <h2 className="h2title">Was sind NFTs?</h2>
        <div className="why-wrapper">
          <div className="why-item">
            <img className="icon" src={transparency} alt="transparency"></img>
            <h3 className="h3title">
              <span>Transparenter</span>
              <span>Besitz</span>
            </h3>
            <p className="cardtext">Das Wallet des aktuellen Besitzers eines NFTs, sowie eine Historie vorheriger Besitzerwallets sind über die Blockchain jederzeit transparent einsehbar. Dadurch lässt sich Besitz jederzeit und überall leicht nachweisen.</p>
          </div>
          <div className="why-item">
            <img className="icon" src={authentic} alt="authentic"></img>
            <h3 className="h3title">
              <span>Verifizierbar &</span>
              <span>Authentisch</span>
            </h3>
            <p className="cardtext">Neben der Besitzhistorie eines NFTs kann zudem auch das Wallet des Herausgebers eingesehen werden. Dadurch lassen sich NFTs von Kopien unterscheiden, was es leicht macht, Originale zu verifizieren.</p>
          </div>
          <div className="why-item">
            <img className="icon" src={fast_transfer} alt="fast_transfer"></img>
            <h3 className="h3title">
              <span>Blitzschneller</span>
              <span>Transfer</span>
            </h3>
            <p className="cardtext">NFTs sind digitale Güter, die sowohl digitale als auch physische Besitztümer, Mitgliedschaften oder Ansprüche repräsentieren. Über die Blockchain können NFTs in Sekunden transferiert werden, was liquiden Handel ermöglicht.</p>
          </div>
        </div>
      </div>

       <div className="" id="newsletter">
        <h2 className="h2title">
          <span>Keine Neuigkeiten mehr verpassen!</span>
          <span>Der Toleo Newsletter!</span>
          </h2>
        <form className="contact-form" /*</div>onSubmit={handleSubmit} */ action="https://toleo.us14.list-manage.com/subscribe/post" method="POST">
          <input type="hidden" name="u" value="1a55d5fa6b1bfa2a70756d589" />
          <input type="hidden" name="id" value="ab9cbf158c" />
          <input id="first_name" name="FNAME" type="text" placeholder="Vorname" />
          <input id="last_name" name="LNAME" type="text" placeholder="Nachname" />
          <input
            placeholder="E-Mail"
            id="email"
            name="EMAIL"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <select id="country" name="MMERGE3" selected='keine'>
              <option className="keine" value="" selected disabled hidden>Zunftzugehörigkeit: keine</option>
              <option value="constaffel">Constaffel</option>
              <option value="saffran">Saffran</option>
              <option value="meisen">Meisen</option>
              <option value="schmiden">Schmiden</option>
              <option value="weggen">Weggen</option>
              <option value="gerwe-und-schuhmacher">Gerwe und Schuhmacher</option>
              <option value="widder">Widder</option>
              <option value="zimmerleuten">Zimmerleuten</option>
              <option value="schneidern">Schneidern</option>
              <option value="schiffleuten">Schiffleuten</option>
              <option value="kaembel">Kämbel</option>
              <option value="waag">Waag</option>
              <option value="stadtzunft">Stadtzunft</option>
              <option value="riesbach">Riesbach</option>
              <option value="fluntern">Fluntern</option>
              <option value="drei-koenige">Drei Könige</option>
              <option value="hottingen">Hottingen</option>
              <option value="wiedikon">Wiedikon</option>
              <option value="wollishofen">Wollishofen</option>
              <option value="hard">Hard</option>
              <option value="oberstrass">Oberstrass</option>
              <option value="st-niklaus">St. Niklaus</option>
              <option value="hoengg">Höngg</option>
              <option value="letzi">Letzi</option>
              <option value="schwamendingen">Schwamendingen</option>
              <option value="witikon">Witikon</option>
          </select>

          <button id="submit" type="submit">Abschicken</button>
          {error && <h2 className="message" style={{ color: 'red' }}>{error}</h2>}
          {success && <h2 className="message" style={{ color: '#919191' }}>{success}</h2>}
        </form>
      </div>

      <div className="" id="faq">
        <h2 className="h2title">FAQ</h2>
        <ul className="faq-list">
          {faqArr.map((ele,index)=>{
            return (
            <li className="faq-item" onClick={()=>{setAnswer(index)}}>
              <a>
                <img src={ele.src} alt={ele.alt}></img>
                <p className="faq-text">{ele.text}</p>
              </a>
              <div className="faq-answer">
                <p className={(answer===index?"":"hide-answer")}>{ele.answer}</p>
                <p className={(answer===index?"":"hide-answer")}>{ele.answer2}</p>
              </div>
            </li>
            )

          })}
        </ul>
      </div>

      <div className="" id="footer">
        <div className="footer-wrapper">
          <p></p>
          <a href="/kontakt"><p className="">Kontakt</p></a>
          <a href="/agb"><p>AGBs</p></a>
          <a href="/impressum"><p className="">Impressum</p></a>
          <p></p>
        </div>
      </div>

      {isOpenModal && (
        <div className="modal" id="myModal">
          <div className="modal-content">
          <div className="modal-header">
            <h2 className="modaltitle text-left">Checkout</h2>
            <span onClick={togglingModal} className="close text-right">&times;</span>
          </div>
          <div className="modal-flex">
            <img src={boegimg} className="boeg-img"></img>
            <div className="" id="modal-content">
              <h3 className="nft-title">E-Böögg NFT</h3>
              <p className="subtitle">Sächsilüüte 2023</p>
              <div className="mint-box">
                <p className="mint-box-title">
                  Anzahl:
                </p>
                <div className="quantity-box">
                  <button onClick={minusAmount} className="">-</button>
                  <span id="mintAmount" className="">1</span>
                  <button onClick={addAmount} className="">+</button>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-8">
            <div className="price-wrap">
              <h3 className="minortitle">
                <span className="bold">Gesamtpreis</span>
                <span>inkl. 7.7% MWST</span>
                </h3>
              <div className="flex flex-col items-end justify-center">
                <p className="text-xl font-bold"><span id="ethpice" className="ml-8">{topayETH}</span> ETH</p>
                <h3 className="text-xl"><span id="chfprice" className="ml-8">{topayCHF}</span> CHF</h3>
              </div>
            </div>
            <p className="minortitle payment-method-title text-left text-2xl leading-5 payment-title mt-8 mb-8 font-bold">Zahlungsart</p>
            <div className="text-left">
              {
                crypt == true ? ( 
                <div className="mb-4" id="mainradio">
                <input type="radio" id="html" name="paymethod" value="crypto" onClick={changepay}></input>
                <label className="text-[20px]" for="html"> <img src={eth} alt="eth" /> Crypto (Ethereum)</label>
                </div>) : (
                  <div className="mb-4" id="mainradio">
                  <input type="radio" id="html" name="paymethod" value="crypto" onClick={changepay} disabled></input>
                  <label className="text-[20px] payment-method" for="html"> 
                    <img src={eth} alt="eth" /> 
                    <span>Kein Wallet für Cryptozahlung verbunden</span>
                  </label>
                  </div>
                  )
              }


              <div className="mb-4" id="mainradio">
                <input type="radio" id="css" name="paymethod" value="invoice" onClick={changepay}></input>
                <label className="text-[20px]" for="css"> <img src={invoice} alt="card" /> Rechnung</label>
              </div>
              <p className="hidden" id="mainerror">Aktuell sind keine Kreditkartenzahlungen möglich.</p>
              <div className="hidden" id="invoiceex">
                <p>Bitte überweisen Sie den oben genannten Betrag an folgende Bankverbindung:</p><br></br>

                <p>CH53 0844 0257 2399 8200 1</p><br></br>

                <p>Alea AG</p>
                <p>Schaffhauserstrasse 188</p>
                <p>CH-8057 Zürich</p>
                <br></br>
                <p><span className="font-bold">Wichtig:</span> Vermerken Sie im Zahlungszweck der Überweisung Ihre E-Mail-Adresse. Wir stellen Ihnen Ihre E-Böögg NFTs auf diese zeitnah zu.</p>
              </div>
            </div>
            
            <div className="">
              <div className="mb-4" id="mainradio">
                <input type="checkbox" id="agb" name="access" value="agb" onClick={changeAGB}></input>
                <label className="text-[14px]" for="agb">Ich habe die<a href="/agb" target="_blank" className="text-[#1B40FF]">AGB</a>gelesen und stimme zu.</label>
              </div>
            </div>
            {/*
              <div className="mb-4" id="mainradio">
                <input type="checkbox" id="privacy" name="access" value="privacy"></input>
                <label className="text-[20px]" for="privacy"> Ich habe die <a href="#" className="text-[#1B40FF]">Datenschutzerklärung</a> gelesen.</label>
              </div>
            </div>
            */}
            <div className="h-full hidden" id="buybuttons">
              <button  onClick={buyEth} id="payCrypto" className="w-full bg-[#183ADF] rounded-[30px] flex items-center justify-center text-2xl font-bold p-6 mt-8 hidden">Zahle {topayETH} ETH via Wallet</button>
              <p className="hidden" id="cryptoError">Zu wenig ETH in Wallet</p>
              <button  onClick={buyChf} id="payCredit" className="w-full bg-[#183ADF] rounded-[30px] flex items-center justify-center text-2xl font-bold p-6 mt-8 hidden">Zahle {topayCHF} CHF via Stripe.com</button>
              <button  id="payInvoice" className="hidden w-full bg-[#183ADF] rounded-[30px] flex items-center justify-center text-2xl font-bold p-6 mt-8" onClick={togglingThank}>
                Kauf per Rechnung
              </button>
              <p className="hidden" id="mintError"></p>
            </div>
          </div>

        </div>
        </div>
      )}

      {isOpenThank && (
        <div className="thank-wrapper" id="thank">
          <div className="grid grid-cols-2" id="header">
            <div className="" id="">
              <img src={logo} className="logo" alt="main logo"></img>
            </div>
            <div>
              <button className="hover:underline" id="connectBtn">0xFB23ak4Kld...</button>
            </div>
          </div>
          <div className="thank-info">
            <img src={boegimg} className="mx-auto boeg-image" alt="main nft"></img>
            <div className="info-content">
              <img src={checkmark} className="mx-auto checkmark" alt="checkmark"></img>
              <h2 className="thank-title">
                Vielen Dank!
              </h2>
              <p className="thank-nft-name">
                NFT Kauf erfolgreich. Du besitzt nun Böögg #103!
              </p>
              <a href="#" className="nft-open-link">
                <img className="opensea" src={opensea} alt="opensea" /> <p>Jetzt Böögg #103 auf OpenSea ansehen</p>
              </a>
            </div>
          </div>
          <div className="sale-details">
            <div className="dropdown-header" onClick={toggling}>
              <p className="dropdown-title">
                NFT Details <img className="angle-icon" src={angle_down} alt="angle-down"></img>
              </p>
              {isOpen && (
                <ul className="sale-details-list">
                  <li className="details-list-item">
                    <a href="https://opensea.io/collection/boeoegg-nft-2023" target="_blank" className="outer-link">
                      Opensea
                      <img className="outer-link" src={link} alt="outer link"></img>
                    </a> 
                    <p className="token"></p>
                  </li>
                  <li className="details-list-item">
                    <a href="https://etherscan.io/address/0xCa266D356E0e5450E89658C2A836d6f8DB54e1bc" target="_blank" className="outer-link">Contract ansehen <img className="outer-link" src={link} alt="outer link"></img></a>
                    <p className="token">0XCA26...E1BC</p>
                  </li>
                  <li className="details-list-item">
                    <p>
                      Minting Ende
                    </p>
                    <p>
                      01.05.2023
                    </p>
                  </li>
                  <li className="details-list-item">
                    <p>
                      Token Typ
                    </p>
                    <p>
                      erc721
                    </p>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="mt-[11rem]" id="newsletter">
            <h2 className="text-xl lg:text-2xl 2xl:text-3xl h31title">Jetzt als Böögg Eigentümer verewigen!</h2>
            <p className="">Als Erstbesitzer eines E-Böögg NFTs erwarten Sie spannende Vorteile & Neuigkeiten</p>
            <form className="conact-form" onSubmit={handleSubmit}>
              <input id="first_name" name="first_name" type="text" placeholder="Vorname" />
              <input id="last_name" name="last_name" type="text" placeholder="Nachname" />
              <input
                placeholder="john@example.com"
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
              />
              <div className="form-dropdown-wrapper">
                <div className="dropdown-header" onClick={togglingZunft}>
                  <p className="dropdown-title">
                    Zunft <img className="angle-icon" src={angle_down} alt="angle-down"></img>
                  </p>
                </div>
                {isOpenZunft && (
                  <ul className="sale-details-list">
                    <li className="details-list-item checked">
                      <input name="zunft" id="keine" type="radio" />
                      <label for="kine">
                        Keine Zunftzugehörigkeit
                      </label>
                    </li>
                    <li className="details-list-item">
                      <input name="zunft" id="st_niklaus" type="radio" />
                      <label for="st_niklaus">
                        Zunft St. Niklaus
                      </label>
                    </li>
                    <li className="details-list-item">
                      <input name="zunft" id="gerwe" type="radio" />
                      <label for="gerwe">
                        Gerwe und Schuhmacher
                      </label>
                    </li>
                    <li className="details-list-item">
                      <input name="zunft" id="zunft3" type="radio" />
                      <label for="zunft3">
                        Zunft 3
                      </label>
                    </li>
                    <li className="details-list-item">
                      <input name="zunft" id="zunft4" type="radio" />
                      <label for="zunft4">
                        Zunft 4
                      </label>
                    </li>
                  </ul>
                )}
              </div>
              <button type="submit">Submit</button>
              {error && <h2 style={{ color: 'red' }}>{error}</h2>}
              {success && <h2 style={{ color: '#919191' }}>{success}</h2>}
            </form>
          </div>
        </div>

      )}
    </div>
    </div>
        </div>
  );
}
export default App; 
