import React from "react"; 
import logo from './assets/toleologo.png'
import boegimg from './assets/boeg2.png'
import combineImg from './assets/combine.png'
 
import './App.scss';
 
function AGB() { 
  return ( 
    <div id="main" className="App text-white bg-black">
        <div className="page-padding">
        <div className="container-large">
        <div className="flex items-center justify-between" id="header">
        <div className="" id="">
          <a href="/"><img src={logo} className="logo"></img></a>
        </div>
        <div className="" id="">
          
        </div>
      </div>

      <div className="" id="banner">
        <div className="" id="headerimage">
          <div className="mx-auto" id="">
            <img src={combineImg} className="mx-auto boeg-image" alt="main nft"></img>
            {/* <img src={checkmark} className="mx-auto w-1/2 checkmark-image" alt="checkmark"></img> */}
          </div>
        </div>
        <div className="" id="buysection">
          <div className="title-wrapper">
          <h1 className="maintitle subpage">Allgemeine Geschäfts-<br></br>bedingungen</h1>
          </div>
          <a href="mailto:team@toleo.ch" className="impressum-txt">team@toleo.ch</a>
        </div>
      </div>
      <div className="">
        <h2 className="text-2xl text-left">1. Umfang</h2>
        <p className="text-left">Diese Allgemeinen Geschäftsbedingungen (im Folgenden "AGB") regeln die Nutzung der Webseite https://toleo.ch ("Webseite") durch Nutzer ("Nutzer") und den Verkauf von Non-Fungible Tokens (NFTs) von der Alea AG, Schaffhauserstrasse 188, CH-8057 Zürich ("Verkäufer") an die Nutzer über die Webseite (https://toleo.ch). Der Verkäufer ist der Eigentümer der Webseite und betreibt sie. Die männliche Bezeichnung „Nutzer“ in diesen AGB gelten auch für die weibliche Form „Nutzerin“; aus Gründen der Lesbarkeit wurde jeweils nur eine Form verwendet.</p>
        <br></br>
        <p className="text-left">Durch die Nutzung der Webseite und/oder den Kauf eines NFTs vom Verkäufer über die Webseite erklärt sich der Nutzer mit diesen AGB einverstanden. Der Nutzer muss diese AGB jederzeit einhalten, wenn er diese Webseite nutzt. Wenn der Nutzer nicht mit diesen AGB einverstanden ist, ist ihm die Nutzung der Webseite und/oder der Kauf eines NFTs über die Webseite untersagt und er muss die Webseite unverzüglich verlassen.</p>
        <br></br>
        <p className="text-left">Der Nutzer kann diese Webseite nur dann nutzen und einen NFT-Kauf über diese Webseite tätigen, wenn er sich bereit erklärt, einen verbindlichen Vertrag mit dem Verkäufer abzuschliessen. Diese AGB stellen einen rechtsverbindlichen Vertrag zwischen dem Nutzer und dem Verkäufer dar. Der Nutzer muss nach dem anwendbaren Recht rechtsfähig sein, einen verbindlichen Vertrag mit dem Verkäufer abzuschliessen. Wenn der Nutzer diese AGB im Namen eines Unternehmens, einer Organisation oder einer anderen juristischen Person akzeptiert, bestätigt er, dass er befugt ist, diese Person an diese ABG zu binden.</p>
        <br></br>
        <p className="text-left">Der Verkäufer akzeptiert keine abweichenden AGB des Nutzers. Abweichungen von diesen AGB sind nur wirksam, wenn der Verkäufer diesen schriftlich zustimmt. Dies gilt auch dann, wenn der Verkäufer der Einbeziehung solcher abweichenden AGB nicht ausdrücklich widerspricht.</p>
        <h2 className="text-2xl text-left mt-12">2.Einschränkungen der Nutzung der Webseite</h2>
        <p className="text-left">Diese Webseite darf unter keinen Umständen von Personen mit Wohnsitz in einem Land, in welchem Beschränkungen für den Handel mit digitalen Vermögenswerten gelten, genutzt werden. Falls der in einer von der Europäischen Union, einem Mitgliedstaat der Europäischen Union, der Schweiz oder von den USA veröffentlichten Liste über finanzielle Sanktionen aufgeführt ist, darf er die Webseite nicht nutzen. Die Informationen und Dienste auf dieser Webseite sind nicht für die Verbreitung an oder die Nutzung durch natürliche oder juristische Personen in Ländern bestimmt, in denen eine solche Verbreitung oder Nutzung rechtswidrig wäre oder die den Verkäufer einer Bewilligungspflicht in einem solchen Land unterwerfen würde.
</p><br></br>
        <p className="text-left">Die Webseite ist für Nutzer bestimmt, die handlungsfähig und mindestens 18 Jahre alt sind. Durch die Nutzung der Webseite bestätig der Nutzer, dass er mindestens 18 Jahre alt ist.</p>
        <h2 className="text-2xl text-left mt-12">3. Definition von NFT und Content</h2>
        <p className="text-left">Für die Zwecke dieser AGB ist ein „NFT“ ein Non-Fungible Token, der auf einer Blockchain registriert ist, das Eigentum an einem bestimmten Inhalt (Content), wie z. B. einer Bilddatei, darstellt und nicht austauschbar ist. In diesen AGB bezieht sich NFT nur auf NFTs, welche vom Verkäufer über die Webseite zum Verkauf angeboten werden, sofern sich hieraus nichts anderes ergibt. Die vom Verkäufer angebotenen NFTs werden jeweils auf der Webseite näher beschrieben. Das auf der Webseite angebotene NFT ist kein Wertpapier, hat keinen Investitionscharakter und gibt dem Nutzer keine finanziellen, forderungs- oder wertpapierähnlichen Rechte.</p>
        <br></br>
        <p className="text-left">„Content“ bezeichnet alle Inhalte, die durch das gekaufte NFT repräsentiert oder dargestellt werden und/oder darin enthalten sind. Der Nutzer erkennt an und erklärt sich damit einverstanden, dass der Verkäufer (oder gegebenenfalls seine Lizenzgeber) alle Rechte, Titel und Interessen am Content sowie alle geistigen Eigentumsrechte daran besitzt. Die Rechte, die die Käufer am Content mit einem NFT erwerben, sind auf die in der Lizenz gemäss diesen AGB beschriebenen Rechte beschränkt. Der Verkäufer behält sich alle Rechte am Content vor, die den Käufern in dieser Lizenz nicht ausdrücklich gewährt werden.</p>
        <h2 className="text-2xl text-left mt-12">4. Kauf des NFT</h2>
        <p className="text-left">Jede Übertragung eines NFT an den Nutzer wird auf der Ethereum-Blockchain registriert.</p><br></br>
        <p className="text-left">Der Nutzer hat die Möglichkeit, den Kaufpreis mit einer Kryptowährung, Kreditkarte oder auf die hinterlegte Bankverbindung des Verkäufers zu zahlen. Bei der Zahlung per Banküberweisung überweist der Nutzer den Kaufpreis auf die hinterlegte Bankverbindung des Verkäufers, wobei er seine E-Mail-Adresse im Zahlungszweck angibt. Der Verkäufer und/oder ein vom Verkäufer beauftragter Dienstleister erstellt eine Wallet, bucht die NFTs in diese Wallet ein und liefert die Wallet mit den NFTs an die vom Nutzer im Zahlungszweck der Banküberweisung angegebene Kontaktadresse. Falls der Nutzer seine eigene Wallet verwenden möchte und/oder den Kauf über eine Kreditkartenzahlung oder eine Kryptowährungszahlung durchführt, muss der Nutzer im Besitz einer kompatiblen digitalen Wallet sein, damit die Transaktion durchgeführt werden kann.</p><br></br>
        <p className="text-left">Der Kaufpreis für NFT ist in Schweizer Franken / CHF angegeben. Preise in anderen Währungen/Kryptowährungen dienen zur Veranschaulichung und sind als Näherungswerte zu verstehen und sind unverbindlich.</p><br></br>
        <p className="text-left">Der Kaufpreis versteht sich inklusive Schweizer Mehrwertsteuer. Bei jeder Transaktion durch ein vom Verkäufer unabhängiges Zahlungssystem, Wallet-Anbieter oder Ähnliches können zusätzliche Gebühren anfallen, einschliesslich Blockchain-Transaktionsgebühren ("Gas Fee"). Solche Gebühren können unvorhersehbar sein und liegen ausserhalb der Kontrolle des Verkäufers. Insbesondere kann sich die Höhe der Gas Fee und/oder Kreditkartengebühren in unvorhersehbarer Weise ändern, was ausserhalb der Kontrolle des Verkäufers liegt. Der Nutzer akzeptiert das Risiko, dass die Gas Fee oder eine andere Gebühr von Dritten möglicherweise auch dann zu zahlen ist, wenn die Transaktion aus einem unvorhergesehenen Grund fehlgeschlagen ist.</p><br></br>
        <p className="text-left">Um eine Bestellung für NFT aufzugeben, folgt der Nutzer den Anweisungen auf der Webseite. Der Kaufvertrag für ein NFT kommt zustande, wenn der Nutzer auf die Schaltfläche "Kaufen" klickt und die Zahlung erfolgt. Der Verkauf endet am 1. Mai 2023 ca. um 18:00. Der Kaufvertrag muss bis zu diesem Zeitpunkt zustande kommen. Nach der Abwicklung der Zahlung erhält der Nutzer innerhalb eines annehmbaren Zeitraums die entsprechende(n) NFT in seiner digitalen Wallet.</p><br></br>
        <p className="text-left">Vorbehaltlich der Einhaltung dieser AGB hat der Nutzer das Recht, über die vom Verkäufer gekauften NFTs frei zu verfügen. Sofern nicht ausdrücklich anders geregelt, umfasst der NFT-Kauf keine geistigen Eigentumsrechte am Content. Alle Urheberrechte und geistigen Eigentumsrechte am Content und an der Webseite gehören dem Verkäufer. Mit dem Kauf eines NFT vom Verkäufer wird der Nutzer im Rahmen einer begrenzten, nicht-exklusiven Lizenz berechtigt, den Content für persönliche, nicht-kommerzielle Zwecke zu nutzen, reproduzieren und auszustellen. Ohne vorherige Zustimmung des Verkäufers ist es dem Nutzer insbesondere untersagt, den Content für kommerzielle Zwecke zu nutzen, den Content ganz oder teilweise zu ändern, zurückentwickeln (reverse engineering) oder anderswie anzupassen.</p>
        <h2 className="text-2xl text-left mt-12">5. Blockchain-bezogene Risiken</h2>
        <p className="text-left">Der Nutzer ist sich bewusst, dass der NFT-Markt hochvolatil ist und dass der Kaufwert eines NFT und/oder einer Kryptowährung nach dem NFT-Kauf sowohl nach oben als auch nach unten schwanken kann. Die Preisschwankung einer Kryptowährung kann sich auch direkt auf den Preis von NFTs auswirken.</p><br></br>
        <p className="text-left">Das Eigentum am NFT wird durch ein bestimmtes Protokoll (Smart Contract) in Ethereum sichergestellt und überprüft. Der Verkäufer hat keine Kontrolle über die öffentlichen Blockchains und/oder Smart Contracts, mit welchen der Nutzer interagieren kann.</p><br></br>
        <p className="text-left">Ausserdem sind Transaktionen auf der Blockchain unumkehrbar und der Verkäufer hat nicht die Möglichkeit, Transaktionen auf der Blockchain rückgängig zu machen.</p><br></br>
        <p className="text-left">Daher erkennt der Nutzer an, dass der Kauf eines NFT ausreichende Kenntnisse und Erfahrungen in geschäftlichen und finanziellen Angelegenheiten sowie ein ausreichendes Verständnis der Blockchain-Technologie erfordert. Der Nutzer ist sich dem Risiko des teilweisen oder vollständigen Wertverlusts eines NFT nach dessen Kauf bewusst. Diese und andere Risiken können in unvorhergesehenen Variationen auftreten. <span className="font-bold">Mit der Annahme dieser AGB erkennt der Nutzer an, dass der Verkäufer nicht für Verluste und/oder Schäden im Zusammenhang mit der Nutzung der Blockchain-Technologie in Verantwortung gezogen werden kann.</span></p>
        <h2 className="text-2xl text-left mt-12">6. Keine Rechtsberatung / keine Beratung zum Kauf von Wertpapieren</h2>
        <p className="text-left">Der Inhalt der Webseite dient lediglich der allgemeinen Information und darf nicht als Beratung oder Aufforderung zur Zeichnung oder zum Kauf von Wertpapieren oder anderen Finanzinstrumenten in irgendeinem Land ausgelegt werden, selbst wenn eine solche Beratung oder Aufforderung rechtmässig gemacht werden könnte. Der Inhalt der Webseite stellt insbesondere keine Beratung und/oder Kaufempfehlung dar, auf welche der Nutzer sich verlassen sollte. Der Verkäufer bietet keinerlei Steuer- , Finanz- oder Rechtsberatung an. </p>
        <h2 className="text-2xl text-left mt-12">7. Einhaltung des geltenden Rechts</h2>
        <p className="text-left">Der Nutzer bestätigt, dass er die Steuervorschriften des Landes, in welchem er steuerpflichtig ist, einhält. Der Nutzer ist sich bewusst, dass der Kauf von NFT steuerliche Konsequenzen für ihn haben kann, einschliesslich der Verpflichtung, sein Einkommen und seine Vermögenswerte bei den zuständigen Steuerbehörden zu deklarieren.</p><br></br>
        <p className="text-left">Der Nutzer ist allein dafür verantwortlich, dass er durch die Nutzung der Webseite, inklusive den NFT-Kauf über die Webseite, nicht gegen das auf ihn anwendbare Recht verstösst.</p><br></br>
        <p className="text-left">Es liegt in der alleinigen Verantwortung der Nutzer, sich bei Bedarf von Rechts- oder Steuerexperten beraten zu lassen.</p>
        <h2 className="text-2xl text-left mt-12">8. Digitale Wallets der Drittanbieter</h2>
        <p className="text-left">Verwendet der Nutzer die digitalen Wallets, welche von einem Drittanbieter betrieben werden, gelten diese AGB für die Nutzung solcher digitalen Wallets nicht. Es liegt in der alleinigen Verantwortung des Nutzers, dass er mit den allfälligen Geschäftsbedingungen des Drittanbieters vertraut ist. Insbesondere können dabei Gebühren in unvorhersehbarer Höhe anfallen. </p><br></br>
        <p className="text-left">Der Nutzer ist allein für die Sicherheit seiner digitalen Wallet verantwortlich. Der Verkäufer ist nicht im Besitz der privaten Schlüssel (Private Key) für die digitale Wallet des Nutzers.</p><br></br>
        <p className="text-left">Der Verkäufer schliesst jegliche Haftung (soweit gesetzlich zulässig) im Zusammenhang mit der digitalen Wallet des Nutzers aus.</p>
        <h2 className="text-2xl text-left mt-12">9. Sekundärmarkt</h2>
        <p className="text-left">Mit dem Kauf des NFT auf der Webseite erhält der Nutzer das Recht, das NFT auf Marktplätzen Dritter zum Verkauf anzubieten, vorbehaltlich dieser AGB und allfälliger AGB solcher Marktplätze Dritter.</p><br></br>
        <p className="text-left">Im Falle einer Sekundärmarkttransaktion gehen die mit dem jeweiligen NFT verbundenen Rechte und Pflichten des Nutzers auf den neuen Käufer über. Dies gilt auch für jede nachfolgende Sekundärmarkttransaktion.</p><br></br>
        <p className="text-left">Der Verkäufer ist nicht an Sekundärmarkttransaktionen auf Marktplätzen Dritter beteiligt und schliesst jegliche Haftung (soweit gesetzlich zulässig) im Zusammenhang mit solchen Sekundärmarkttransaktionen aus. Es liegt in der alleinigen Verantwortung des Nutzers zu prüfen, welche Rechte und Pflichten mit dem Sekundärmarktverkauf des auf der Webseite erworbenen NFT verbunden sind.</p><br></br>
        <p className="text-left">Die Sekundärmarkttransaktionen mit dem auf der Webseite erworbenen NFT können einer Provision zugunsten des Verkäufers und/oder eines Dritten (z.B. Creators) unterliegen. Mit dem Kauf eines NFT auf der Webseite akzeptiert der Nutzer, dass eine solche Provision anfallen kann. Der Verkäufer hat das Recht, die Handelbarkeit des NFTs auf Marktplätzen Dritter einzuschränken (z.B. via Smart Contract), insbesondere wenn solche Marktplätze Dritter keine Provision zugunsten des Verkäufers zulassen. </p><br></br>
        <p className="text-left">Im Rahmen von Sekundärmarkttransaktionen ist der Nutzer selbst dafür verantwortlich, den Erwerber über die mögliche Provision sowie über die Bestimmungen dieser AGB vertraut zu machen und dem Erwerber die Verpflichtung aufzuerlegen, (a) diese Provision zugunsten des Verkäufers und/oder eines Dritten (z.B. Creators) zu leisten und (b) diese Verpflichtung bei weiteren Transaktionen an nachfolgende Erwerber weiterzugeben.</p>
        <h2 className="text-2xl text-left mt-12">10. Andere NFT-Funktionen </h2>
        <p className="text-left">Zusammen mit dem gekauften NFT kann der Verkäufer dem Nutzer weitere Funktionen ("Features") je nach Verfügbarkeit anbieten. Solche Funktionen können z.B. aus dem Zugang zu (virtuellen) Metaverse-Erfahrungen und/oder dem Zugang zu Veranstaltungen bestehen. Die Funktionen (falls verfügbar/vorhanden) können vom Verkäufer nach seinem Ermessen angeboten werden und können gesonderten Bedingungen unterliegen (in diesem Fall wird der Nutzer ausdrücklich darauf hingewiesen). Der Verkäufer kann nach eigenem Ermessen entscheiden, ob er Funktionen/Features zusammen mit dem Kauf eines NFT oder zu einem späteren Zeitpunkt anbietet.</p>
        <h2 className="text-2xl text-left mt-12">11. Nutzung der Webseite</h2>
        <p className="text-left">Der Nutzer darf die Webseite nicht für illegale, schädliche, unbefugte, betrügerische oder rechtswidrige Zwecke nutzen oder andere dazu ermutigen, anstiften, fördern oder anweisen.</p><br></br>
        <p className="text-left">Der Nutzer verpflichtet sich, den Zugang zu und/oder die Nutzung der Webseite nicht zu missbrauchen, indem er beispielsweise die Funktionalität der Webseite beeinträchtigt oder auf die Webseite auf andere Weise als durch das vom Verkäufer bereitgestellte Interface zugreift. Dem Nutzer ist es beim Zugriff/Nutzung der Webseite insbesondere untersagt: (i) auf nicht-öffentliche Bereiche der Webseite und/oder zugehörige Software oder Computersysteme des Verkäufers zuzugreifen, diese zu manipulieren oder zu nutzen; (ii) die Verwundbarkeit eines Systems/Netzwerks zu sondieren, zu scannen oder zu testen oder gegen Sicherheits- oder Authentifizierungsmassnahmen zu verstossen oder diese zu umgehen; (iii) den Zugang eines Benutzers, eines Hosts oder eines Netzwerks zu stören oder zu unterbrechen. </p>
        <h2 className="text-2xl text-left mt-12 font-bold">12. Haftung und Haftungsausschluss</h2>
        <p className="text-left font-bold">DURCH DIE NUTZUNG DER WEBSEITE UND/ODER DEN KAUF DES NFT ÜBER DIE WEBSEITE ERKLÄRT SICH DER NUTZER DAMIT EINVERSTANDEN, DASS DIE HAFTUNG DES VERKÄUFERS UND SEINER MUTTERGESELLSCHAFT(EN), VERBUNDENEN UNTERNEHMEN, LEITENDEN ANGESTELLTEN, DIREKTOREN, MITARBEITER, AGENTEN, VERTRETER, PARTNER UND LIZENZGEBER IM GRÖSSTMÖGLICHEN NACH GELTENDEM RECHT ZULÄSSIGEN UMFANG BESCHRÄNKT IST. </p><br></br>
        <p className="text-left font-bold">DER VERKÄUFER ÜBERNIMMT KEINE AUSDRÜCKLICHEN ODER STILLSCHWEIGENDEN GEWÄHRLEISTUNGEN, ZUSICHERUNGEN ODER ZUSTIMMUNGEN IN BEZUG AUF DIE WEBSITE ODER DER NFTS, DIE ÜBER DIE WEBSITE ODER IM INTERNET IM ALLGEMEINEN ZUR VERFÜGUNG GESTELLT WERDEN.  DIE NFTS WERDEN AUF DER WEBSEITE OHNE MÄNGELGEWÄHR UND OHNE GARANTIEN JEGLICHER ART ANGEBOTEN. DER VERKÄUFER GESTATTET NIEMANDEM, IRGENDEINE GARANTIE IN SEINEM NAMEN ZUZUSICHERN. DER NUTZER BESTÄTIGT, DASS DER VERKÄUFER ODER ANDERE IM PROJEKT INVOLVIERTE DRITTE KEINE ZUSICHERUNGEN ODER GARANTIEN ABGEGEBEN HABEN, SEI ES SCHRIFTLICH ODER MÜNDLICH UND DASS DER NUTZER SICH AUF KEINE SOLCHE ZUSICHERUNG ODER GARANTIE VERLASSEN HAT.  </p><br></br>
        <p className="text-left font-bold">DER VERKÄUFER IST NICHT HAFTBAR FÜR HANDLUNGEN UND UNTERLASSUNGEN VON DRITTANBIETERN.</p><br></br>
        <p className="text-left font-bold">DER VERKÄUFER HAFTET NUR FÜR VORSÄTZLICHES ODER GROB FAHRLÄSSIGES VERHALTEN UND SCHLIESST JEDE HAFTUNG FÜR ETWAIGE SCHÄDEN AUS, SOWIET DIES GESETZLICH ZULÄSSIG IST. DER VERKÄUFER SCHLIESST DIE HAFTUNG FÜR VON SEINEN HILFSPERSONEN MIT LEICHTER ODER GROBER FAHRLÄSSIGKEIT ODER MIT VORSATZ VERURSACHTE SCHÄDEN AUS, SOWEIT DIES GESETZLICH ZULÄSSIG IST. JEGLICHE HAFTUNG IST AUF DEN VOM NUTZER BEZAHLTEN KAUFPREIS BESCHRÄNKT. </p><br></br>
        <p className="text-left font-bold">IN KEINEM FALL HAFTET DER VERKÄUFER FÜR INDIREKTE, ZUFÄLLIGE ODER FOLGESCHÄDEN (EINSCHLIESSLICH, JEDOCH NICHT AUSSCHLIESSLICH, FÜR SCHÄDEN WEGEN EINKOMMENSAUSFÄLLEN, ENTGANGENEM GEWINN GESCHÄFTSAUSFÄLLEN, VERLUST VON PROGRAMMEN ODER INFORMATIONEN USW.), DIE AUS DER NUTZUNG ODER DER UNMÖGLICHKEIT DER NUTZUNG DER WEBSITE INSBESONDERE DURCH DEN NFT-KAUF ÜBER DIE WEBSEITE ENTSTANDEN SIND. </p><br></br>
        <p className="text-left font-bold">DER NUTZER HAT ALLE ZUMUTBAREN MASSNAHMEN ZUR SCHADENSMINIMIERUNG UND -MINDERUNG ZU TREFFEN. </p><br></br>
        <p className="text-left font-bold">DIE BESTIMMUNGEN DIESES PARAGRAPHEN ERLÖSCHEN NICHT MIT DEM AUSSERKRAFTTRETEN DIESER AGB.</p>
        <h2 className="text-2xl text-left mt-12">13. Schadloshaltung</h2>
        <p className="text-left">Der Nutzer hält den Verkäufer vollumfänglich schadlos von Ansprüchen Dritter im Zusammenhang mit der Nutzung der Webseite, NFT-Kauf sowie diesen AGB gegen den Verkäufer. Dies gilt insbesondere auch bei der Abwehr ungerechtfertigter Ansprüche Dritter. Von der Schadloshaltung sind sämtliche Kosten erfasst (inklusive Anwaltskosten und Gerichtskosten), welche mit den Ansprüchen Dritter zusammenhängen. </p>
        <h2 className="text-2xl text-left mt-12">14. Webseite und Funktionen auf einer “As-is” Basis</h2>
        <p className="text-left">Der Verkäufer stellt die Webseite „wie besehen“ und „wie verfügbar“ (“As-is” Basis) bereit und gewährleistet nicht, dass sie fehlerfrei oder ununterbrochen funktioniert. Der Verkäufer gewährleistet nicht, dass die Webseite frei von Fehlern oder Viren ist. Die Benutzer sind dafür verantwortlich, ihre Computerprogramme für den Zugriff auf die Webseite zu konfigurieren, und sie sollten ihre eigene Virenschutzsoftware verwenden. Der Verkäufer schliesst seine Haftung im gesetzlich zulässigen Umfang für Schäden aus, die sich aus der Nutzung der Webseite ergeben, einschliesslich aber nicht beschränkt auf den Kauf von NFT über die Webseite.</p><br></br>
        <p className="text-left">Die Nutzer erklären sich damit einverstanden, dass sich die Funktionalität der Webseite von Zeit zu Zeit nach dem Ermessen des Verkäufers ändern kann. Der Verkäufer kann den Zugang zur Webseite und/oder den Kauf von NFT über die Webseite dauerhaft oder vorübergehend einstellen. Der Verkäufer behält sich auch das Recht vor, nach eigenem Ermessen jederzeit Zugangs-/Nutzungsbeschränkungen zu erlassen. </p>
        <h2 className="text-2xl text-left mt-12">15. Gültigkeit und Änderungen </h2>
        <p className="text-left">Soweit beim NFT-Kauf über die Webseite keine weiteren Vereinbarungen zwischen dem Nutzer und dem Verkäufer getroffen werden, wird der Inhalt des NFT-Kaufvertrags zwischen dem Nutzer und Verkäufer durch diese AGB in ihrer jeweils aktuellen Fassung zum Zeitpunkt des Abschlusses des jeweiligen NFT-Kaufvertrages bestimmt. Der Verkäufer kann diese AGB aus berechtigten Gründen ändern. Ändert der Verkäufer diese AGB nach Abschluss eines NFT-Kaufvertrages mit dem Nutzer, so gelten diese Änderungen ab der Annahme durch den Nutzer. </p><br></br>
        <p className="text-left">Sollte eine Bestimmung dieser AGB für ungültig oder nicht durchsetzbar sein, so wird diese Bestimmung auf das erforderliche Mindestmass beschränkt oder aufgehoben, und die Wirksamkeit übriger Bestimmungen dieser AGB bleibt hiervon unberührt. </p>
        <h2 className="text-2xl text-left mt-12">16. Geltendes Recht und Gerichtsstand</h2>
        <p className="text-left">Diese Bedingungen unterstehen dem materiellen Schweizer Recht unter Ausschluss des Kollisionsrechts. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf ist nicht anwendbar. Für Streitigkeiten, die sich aus oder im Zusammenhang mit diesen AGB ergeben, sind ausschliesslich die ordentlichen Gerichte in Zürich ZH, Schweiz, zuständig.</p><br></br>
      </div>

      <div>
        <a href="/"><button className="mt-8   float-right text-lg lg:text-2xl 2xl:text-3xl" id="openModalBtn" >Zur Startseite</button></a>
      </div>

      <div className="" id="footer">
        <div className="footer-wrapper">
          <a href="/kontakt"><p className="">Kontakt</p></a>
          <a href="/agb"><p>AGBs</p></a>
          <a href="/impressum"><p className="">Impressum</p></a>
          <p></p>
        </div>
      </div>

    </div> 
    </div> 
    </div> 
  ); 
} 
 
export default AGB; 
