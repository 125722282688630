import React from "react"; 
import logo from './assets/toleologo.png'
import boegimg from './assets/boeg_final.png'
import combineImg from './assets/combine.png'
 
import './App.scss';
 
function Success() { 
  return ( 
    <div id="main" className="App text-white bg-black">
        <div className="page-padding">
        <div className="container-large">
        <div className="flex items-center justify-between" id="header">
        <div className="" id="">
          <a href="/"><img src={logo} className="logo"></img></a>
        </div>
        <div className="" id="">
          
        </div>
      </div>

      <div className="" id="banner">
        <div className="" id="headerimage">
          <div className="mx-auto" id="">
            <img src={combineImg} className="mx-auto boeg-image" alt="main nft"></img>
            {/* <img src={checkmark} className="mx-auto w-1/2 checkmark-image" alt="checkmark"></img> */}
          </div>
        </div>
        <div className="" id="buysection">
          <div className="title-wrapper">
          <h1 className="maintitle subpage">Herzlichen Dank</h1>
          <h1 className="maintitle subpage">für Ihren Kauf!</h1>
          </div>
          <p className="lead">Wie erwähnt, überweisen Sie bitte den genannten Betrag an folgende Bankverbindung:</p>
          <div className="invoicetext">
            <p>CH53 0844 0257 2399 8200 1</p><br></br>

            <p>Alea AG</p>
            <p>Schaffhauserstrasse 188</p>
            <p>8057 Zürich</p>
            <br></br>
            <p><span>Wichtig:</span> Vermerken Sie im Zahlungszweck der Überweisung Ihre E-Mail-Adresse an.</p>
            <p>Wir stellen Ihnen Ihre Böögg NFTs auf diese zeitnah zu.</p><br></br>
            </div>
          <a href="/"><button className="mt-8   float-right text-lg lg:text-2xl 2xl:text-3xl" id="openModalBtn" >Zur Startseite</button></a>
        </div>
      </div>

      <div className="" id="footer">
        <div className="footer-wrapper">
          <p></p>
          <a href="/kontakt"><p className="">Kontakt</p></a>
          <a href="/agb"><p>AGBs</p></a>
          <a href="/impressum"><p className="">Impressum</p></a>
          <p></p>
        </div>
      </div>

    </div> 
    </div> 
    </div> 




  ); 
} 
 
export default Success; 