import React from "react"; 
import logo from './assets/toleologo.png'
import boegimg from './assets/boeg_final.png'
import combineImg from './assets/combine.png'
 
import './App.scss';

function Success() { 

  let handleSubmit, email, handleChange, error, success = null

  return ( 
    <div id="main" className="App text-white bg-black">
        <div className="page-padding">
        <div className="container-large">
        <div className="flex items-center justify-between" id="header">
        <div className="" id="">
          <a href="/"><img src={logo} className="logo"></img></a>
        </div>
        <div className="" id="">
          
        </div>
      </div>

      <div className="" id="banner">
        <div className="" id="headerimage">
          <div className="mx-auto" id="">
            <img src={combineImg} className="mx-auto boeg-image" alt="main nft"></img>
            {/* <img src={checkmark} className="mx-auto w-1/2 checkmark-image" alt="checkmark"></img> */}
          </div>
        </div>
        <div className="" id="buysection">
          <div className="title-wrapper">
          <h1 className="maintitle subpage">Herzlichen Dank</h1>
          <h1 className="maintitle subpage">für Ihren Kauf!</h1>
          </div>
          <p className="lead">Die NFTs werden in den nächsten Minuten an ihre Wallet Adresse versandt.</p>
          <a href="/"><button className="mt-8   float-right text-lg lg:text-2xl 2xl:text-3xl" id="openModalBtn" >Zur Startseite</button></a>
        </div>
      </div>

      <div className="" id="newsletter">
        <h2 className="h2title success">
          <span>Jetzt als Böögg Eigentümer verewigen</span>
          </h2>
          <p className="success">Als Erstbesitzer eines Böögg NFTs erwarten Sie spannende Vorteile & Neuigkeiten.</p>
          <form className="contact-form" /*</div>onSubmit={handleSubmit} */ action="https://toleo.us14.list-manage.com/subscribe/post" method="POST">
          <input type="hidden" name="u" value="1a55d5fa6b1bfa2a70756d589" />
          <input type="hidden" name="id" value="ab9cbf158c" />
          <input id="first_name" name="FNAME" type="text" placeholder="Vorname" />
          <input id="last_name" name="LNAME" type="text" placeholder="Nachname" />
          <input
            placeholder="E-Mail"
            id="email"
            name="EMAIL"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <select id="country" name="MMERGE3" selected='keine'>
              <option className="keine" value="" selected disabled hidden>Zunftzugehörigkeit: keine</option>
              <option value="constaffel">Constaffel</option>
              <option value="saffran">Saffran</option>
              <option value="meisen">Meisen</option>
              <option value="schmiden">Schmiden</option>
              <option value="weggen">Weggen</option>
              <option value="gerwe-und-schuhmacher">Gerwe und Schuhmacher</option>
              <option value="widder">Widder</option>
              <option value="zimmerleuten">Zimmerleuten</option>
              <option value="schneidern">Schneidern</option>
              <option value="schiffleuten">Schiffleuten</option>
              <option value="kaembel">Kämbel</option>
              <option value="waag">Waag</option>
              <option value="stadtzunft">Stadtzunft</option>
              <option value="riesbach">Riesbach</option>
              <option value="fluntern">Fluntern</option>
              <option value="drei-koenige">Drei Könige</option>
              <option value="hottingen">Hottingen</option>
              <option value="wiedikon">Wiedikon</option>
              <option value="wollishofen">Wollishofen</option>
              <option value="hard">Hard</option>
              <option value="oberstrass">Oberstrass</option>
              <option value="st-niklaus">St. Niklaus</option>
              <option value="hoengg">Höngg</option>
              <option value="letzi">Letzi</option>
              <option value="schwamendingen">Schwamendingen</option>
              <option value="witikon">Witikon</option>
          </select>

          {/* <div className="form-dropdown-wrapper">
            <div className="dropdown-header" onClick={togglingZunft}>
              <p className="dropdown-title">
                <span>Zunft</span> 
                <img className="angle-icon" src={angle_down} alt="angle-down"></img>
              </p>
            </div>
            {isOpenZunft && (
              <ul className="sale-details-list">
                <li className="details-list-item checked">
                  <input name="zunft" id="keine" type="radio" />
                  <label for="kine">
                    Keine Zunftzugehörigkeit
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="st_niklaus" type="radio" />
                  <label for="st_niklaus">
                    St. Niklaus
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="gerwe" type="radio" />
                  <label for="gerwe">
                    Gerwe & Schuhmacher
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="constaffel" type="radio" />
                  <label for="zunft3">
                    Gesellschaft zur Constaffel
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="saffran" type="radio" />
                  <label for="zunft4">
                    Saffran
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="meisen" type="radio" />
                  <label for="zunft4">
                    Meisen
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="schmiden" type="radio" />
                  <label for="zunft4">
                    Schmiden
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="weggen" type="radio" />
                  <label for="zunft4">
                    Weggen
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="widder" type="radio" />
                  <label for="zunft4">
                    Widder
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="zimmerleuten" type="radio" />
                  <label for="zunft4">
                    Zimmerleuten
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="schneidern" type="radio" />
                  <label for="zunft4">
                    Schneidern
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="schiffleuten" type="radio" />
                  <label for="zunft4">
                    Schiffleuten
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="kambel" type="radio" />
                  <label for="zunft4">
                    Kämbel
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="waag" type="radio" />
                  <label for="zunft4">
                    Waag
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="Stadtzunft" type="radio" />
                  <label for="zunft4">
                    Stadtzunft
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="riesbach" type="radio" />
                  <label for="zunft4">
                    Riesbach
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="fluntern" type="radio" />
                  <label for="zunft4">
                    Fluntern
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="dreikoenige" type="radio" />
                  <label for="zunft4">
                    Drei Könige
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="hottingen" type="radio" />
                  <label for="zunft4">
                    Hottingen
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="wiedikon" type="radio" />
                  <label for="zunft4">
                    Wiedikon
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="wollishofen" type="radio" />
                  <label for="zunft4">
                    Wollishofen
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="hard" type="radio" />
                  <label for="zunft4">
                    Hard
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="oberstrass" type="radio" />
                  <label for="zunft4">
                    Oberstrass
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="hoengg" type="radio" />
                  <label for="zunft4">
                    Höngg
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="Letzi" type="radio" />
                  <label for="zunft4">
                    Letzi
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="schwamendingen" type="radio" />
                  <label for="zunft4">
                    Schwamendingen
                  </label>
                </li>
                <li className="details-list-item">
                  <input name="zunft" id="witikon" type="radio" />
                  <label for="zunft4">
                    Witikon
                  </label>
                </li>
              </ul>
            )}
          </div> */}

          <button id="submit" type="submit">Abschicken</button>
          {error && <h2 className="message" style={{ color: 'red' }}>{error}</h2>}
          {success && <h2 className="message" style={{ color: '#919191' }}>{success}</h2>}
        </form>
      </div>

      <div className="" id="footer">
        <div className="footer-wrapper">
          <p></p>
          <a href="/kontakt"><p className="">Kontakt</p></a>
          <a href="/agb"><p>AGBs</p></a>
          <a href="/impressum"><p className="">Impressum</p></a>
          <p></p>
        </div>
      </div>

    </div> 
    </div> 
    </div> 
  ); 
} 
 
export default Success; 